import React from 'react';
import { Image, Link, Container, Box, Flex, Text, SimpleGrid } from '@chakra-ui/react';

import twitterIcon from '../../assets/image/footer/twitter.svg';
import telegramIcon from '../../assets/image/footer/telegram.svg';
import mediumIcon from '../../assets/image/footer/medium.svg';
import linktreeIcon from '../../assets/image/footer/linktree.svg';
import seabed from '../..//assets/image/home/seabed.png';

import logo from '../../assets/image/logo.svg';

const MEDIAS = [
  {
    name: 'linktree',
    icon: linktreeIcon,
    link: 'https://linktr.ee/D3XEN'
  },
  {
    name: 'twitter',
    icon: twitterIcon,
    link: 'ttps://x.com/d3x_exchange '
  },
  {
    name: 'medium',
    icon: mediumIcon,
    link: 'https://d3xxyz.medium.com/'
  },
  {
    name: 'telegram',
    icon: telegramIcon,
    link: 't.me/D3Xofficial'
  }
];

const LINKS = [
  {
    label: 'Products',
    links: [
      { label: 'Fiat', link: '' },
      { label: 'Spot', link: 'https://app.d3x.exchange/swap' },
      { label: 'Future', link: '' }
    ]
  },
  {
    label: 'Support',
    links: [
      { label: 'Help center', link: '' },
      { label: 'Press room', link: '' },
      { label: 'Contracts', link: '' },
      { label: 'Media kit', link: '/media-kit' }
    ]
  },
  {
    label: 'Developers',
    links: [
      { label: 'Documentation', link: '' },
      { label: 'GitHub', link: '' },
      { label: 'Audit', link: '' }
    ]
  }
];
// bgImage={`url(${seabed})`}
const NewFooter = () => {
  return (
    <Flex
      position={'relative'}
      bgSize={'100% 100%'}
      minH={'522px'}
      alignItems={'end'}
      // bgImage={`url(${seabed})`}
      backgroundSize={'100% 100%'}>
      <Box width={'100%'}>
        <Container
          width={'95%'}
          maxW="2560px"
          display="flex"
          flexDir="column"
          alignItems={'flex-start'}
          gap={'40px'}
          padding={['40px 20px', null, '20px']}>
          {/* <Image src={seabed} zIndex={'1'} left={0} top={'0'} /> */}

          <SimpleGrid columns={[1, null, 2]} width={'100%'} spacing={[10, null, 0]} position={'relative'} zIndex={'9'}>
            <Box width={'100%'}>
              <Image src={logo} width={'100px'} />
            </Box>
            <Flex width={'100%'} alignItems={'flex-start'} justifyContent={['space-between', null, 'space-around']}>
              {LINKS.map((linkCol) => (
                <Flex
                  key={linkCol.label}
                  flexDir={'column'}
                  alignItems={'flex-start'}
                  gap={'10px'}
                  color={'white'}
                  fontSize={['14px', null, '16px']}>
                  <Text fontWeight={600}>{linkCol.label}</Text>
                  {linkCol.links.map((link) => (
                    <Link key={link.label} opacity={0.5} href={link.link}>
                      {link.label}
                    </Link>
                  ))}
                </Flex>
              ))}
            </Flex>
          </SimpleGrid>
          <Flex
            width={'100%'}
            alignItems={'center'}
            justifyContent={['center', null, 'space-between']}
            paddingY={'25px'}
            flexDir={['column', null, 'row']}
            borderTop={'1px solid rgba(255,255,255,0.1)'}
            gap={'24px'}>
            <Text color={'white'} opacity={0.5}>
              Copyright &copy; 2024 All Rights Reserved
            </Text>
            <Flex alignItems={'center'} gap={'24px'}>
              {MEDIAS.map((item) => (
                <Link key={item.name} href={item.link} isExternal>
                  <Image src={item.icon} width={'20px'} />
                </Link>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
};

export default NewFooter;
