import { PriceApiResult } from '../types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const coinSymbols = {
  BTC: 'BTC',
  DOGE: 'DOGE',
  ETH: 'ETH',
  BNB: 'BNB',
  XRP: 'XRP',
  ADA: 'ADA',
  SOL: 'SOL',
  LTC: 'LTC',
  TRX: 'TRX',
  MATIC: 'MATIC'
};

type CoinSymBole = keyof typeof coinSymbols;

const coinNames = {
  BTC: 'BTC/USDT',
  DOGE: 'DOGE/USDT',
  ETH: 'ETH/USDT',
  BNB: 'BNB/USDT',
  XRP: 'XRP/USDT',
  ADA: 'ADA/USDT',
  SOL: 'SOL/USDT',
  LTC: 'LTC/USDT',
  TRX: 'TRX/USDT',
  MATIC: 'MATIC/USDT'
};
const coinImgs = {
  BTC: 'https://www.cryptocompare.com/media/19633/btc.png',
  DOGE: 'https://www.cryptocompare.com/media/19684/doge.png',
  ETH: 'https://resources.cryptocompare.com/asset-management/2/1659946678476.png',
  BNB: 'https://www.cryptocompare.com/media/1383947/bnb.png',
  XRP: 'https://www.cryptocompare.com/media/19972/ripple.png',
  ADA: 'https://www.cryptocompare.com/media/12318177/ada.png',
  SOL: 'https://resources.cryptocompare.com/asset-management/3/1659972172429.png',
  LTC: '	https://resources.cryptocompare.com/asset-management/18/1662458148716.png',
  TRX: 'https://resources.cryptocompare.com/asset-management/28/1662560287430.png',
  MATIC: 'https://resources.cryptocompare.com/asset-management/4/1660829973365.png'
};
const coinOrder = {
  BTC: 1,
  DOGE: 2,
  ETH: 3,
  BNB: 4,
  XRP: 5,
  ADA: 6,
  SOL: 7,
  LTC: 8,
  TRX: 9,
  MATIC: 10
};

const coinTradeLink = {
  BTC: 'https://story.d3x.exchange/trade?pair=BTC-USD&showInsight=0',
  DOGE: 'https://story.d3x.exchange/trade?pair=DOGE-USD&showInsight=0',
  ETH: 'https://story.d3x.exchange/trade?pair=ETH-USD&showInsight=0',
  BNB: 'https://story.d3x.exchange/trade?pair=BNB-USD&showInsight=0',
  XRP: 'https://story.d3x.exchange/trade?pair=XRP-USD&showInsight=0',
  ADA: '',
  SOL: 'https://story.d3x.exchange/trade?pair=SOL-USD&showInsight=0',
  LTC: '',
  TRX: '',
  MATIC: ''
};

type PriceData = {
  fully_diluted_market_cap: number;
  last_updated: string;
  market_cap: number;
  market_cap_dominance: number;
  percent_change_1h: number;
  percent_change_7d: number;
  percent_change_24h: number;
  percent_change_30d: number;
  percent_change_60d: number;
  percent_change_90d: number;
  price: number;
  tvl: number | null;
  volume_24h: number;
  volume_change_24h: number;
};

type Prices = Record<CoinSymBole, PriceData>;

function createRandomArray(trend = 'up', firstValue: number, lastValue: number): number[] {
  const randomArray: number[] = [firstValue];
  for (let i = 0; i < 10; i++) {
    const v = (0.5 + Math.random()) * firstValue;
    randomArray.push(v);
  }
  randomArray.push(lastValue);
  return randomArray;
}

const translateToPriceApiResult = (data: PriceData, symbol: CoinSymBole): PriceApiResult => {
  const trend = data.percent_change_24h > 0 ? 'up' : 'down';
  const fluctuation = Number(Math.abs(data.percent_change_24h).toPrecision(3));
  const price = Number(Math.abs(data.price).toFixed(4));
  const imgSrc = coinImgs[symbol];
  const chartData = createRandomArray(trend, price * (1 - data.percent_change_24h / 100), price);
  const tradeLink = coinTradeLink[symbol];
  return {
    name: coinNames[symbol],
    trend,
    fluctuation,
    price,
    imgSrc,
    chartData,
    order: coinOrder[symbol],
    tradeLink
  };
};

export const usePrice = () => {
  const queryStr = Object.values(coinSymbols).join(',');
  const query = useQuery({
    queryKey: ['coinmarketcapQuery'],
    queryFn: async () => {
      const response = await axios.get('https://api2.prosperex.xyz/price/');
      const data = response.data.data.data;
      const result = Object.entries(data).map(([symbol, infos]) => {
        const currentQuote = (infos as any)[0].quote.USDT as PriceData;
        return translateToPriceApiResult(currentQuote, symbol as CoinSymBole);
      });

      return result.sort((a, b) => a.order! - b.order!);
    }
  });

  return useMemo(
    () => ({
      data: query.data,
      isSuccess: query.isSuccess
    }),
    [query.data, query.isSuccess]
  );
};
